// types
import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import http from 'apis/http';
import { User } from 'apis/types';
import axios, { AxiosResponse } from 'axios';
import config from 'config';
import moment from 'moment';

export type State = Partial<
    Pick<User, 'id' | 'accessToken' | 'refreshToken' | 'username' | 'name' | 'surname' | 'businessName' | 'phone' | 'trial'>
> & {
    loginError?: SerializedError;
    registerError?: SerializedError;
    // Nello stato, il tipo deve essere per forza serializzabile (Date non è serializzabile)
    expiryDate?: string;
};

export interface SignupState {
    user: Partial<User>;
    password: string;
    prefix: string;
}

export interface LoginState {
    username: string;
    password: string;
}

// initial state
const initialState: State = {
    id: sessionStorage.getItem('id') ? parseInt(sessionStorage.getItem('id') as string) : undefined,
    username: sessionStorage.getItem('username') || undefined,
    accessToken: sessionStorage.getItem('accessToken') || undefined,
    refreshToken: sessionStorage.getItem('refreshToken') || undefined,
    name: sessionStorage.getItem('name') || undefined,
    surname: sessionStorage.getItem('surname') || undefined,
    businessName: sessionStorage.getItem('businessName') || undefined,
    phone: sessionStorage.getItem('phone') || undefined,
    expiryDate: sessionStorage.getItem('expiryDate') || undefined,
    trial: sessionStorage.getItem('trial') === '' ? undefined : sessionStorage.getItem('trial') === 'true'
};

// ==============================|| SLICE - AUTH ||============================== //

export const signup = createAsyncThunk<AxiosResponse, SignupState>(
    'auth/signup',
    async ({ user, password, prefix }, { rejectWithValue }) => {
        const url = `${config.serverUrl}/auth/user/signup`;
        console.log('AUTH - SIGNUP URL', url);
        console.log('AUTH - SIGNUP PARAMS', { user, password });

        try {
            const response = await http.post(
                url,
                { user, password, prefix },
                {
                    validateStatus: (status: number) => status >= 200 && status < 300
                }
            );
            return response;
        } catch (error) {
            if (axios.isAxiosError(error)) return rejectWithValue(error.response?.data ?? error);
            throw error;
        }
    }
);

export const login = createAsyncThunk<User, LoginState>('auth/login', async ({ username, password }, { rejectWithValue }) => {
    const url = `${config.serverUrl}/auth/user/signin`;
    console.log('AUTH - LOGIN URL', url);

    try {
        const { data } = await http.post<User>(
            url,
            {
                username,
                password
            },
            {
                validateStatus: (status: number) => status >= 200 && status < 300
            }
        );
        console.log('AUTH - LOGIN SUCCEDED', data);
        return data;
    } catch (error) {
        console.log('AUTH - LOGIN FAILED', error);
        if (axios.isAxiosError(error)) return rejectWithValue(error.response?.data ?? error);
        throw error;
    }
});

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            delete state.id;
            delete state.username;
            delete state.accessToken;
            delete state.refreshToken;
            delete state.name;
            delete state.surname;
            delete state.businessName;
            delete state.phone;
            delete state.expiryDate;
            delete state.trial;
            sessionStorage.removeItem('id');
            sessionStorage.removeItem('username');
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('refreshToken');
            sessionStorage.removeItem('name');
            sessionStorage.removeItem('surname');
            sessionStorage.removeItem('businessName');
            sessionStorage.removeItem('phone');
            sessionStorage.removeItem('expiryDate');
            sessionStorage.removeItem('trial');
        },
        /**
         * Azione da richiamare a ogni aggiornamento dello user
         * @param state - Vecchio stato
         * @param action - Azione contenente il nuovo user
         */
        update(state, action: PayloadAction<Partial<State> | undefined>) {
            console.log('DISPATCHED UPDATE USER - PAYLOAD', action.payload);
            state.accessToken = action.payload?.accessToken !== undefined ? action.payload.accessToken : state.accessToken;
            state.refreshToken = action.payload?.refreshToken !== undefined ? action.payload.refreshToken : state.refreshToken;
            state.name = action.payload?.name !== undefined ? action.payload.name : state.name;
            state.surname = action.payload?.surname !== undefined ? action.payload.surname : state.surname;
            state.businessName = action.payload?.businessName !== undefined ? action.payload.businessName : state.businessName;
            state.phone = action.payload?.phone !== undefined ? action.payload.phone : state.phone;
            // toISOString salva la data come stringa YYYY-MM-DDTHH:mm:ss.sssZ in UTC
            state.expiryDate = action.payload?.expiryDate !== undefined ? action.payload.expiryDate : state.expiryDate;
            state.trial = action.payload?.trial !== undefined ? action.payload.trial : state.trial;
            sessionStorage.setItem('accessToken', state.accessToken || '');
            sessionStorage.setItem('refreshToken', state.refreshToken || '');
            sessionStorage.setItem('name', state.name || '');
            sessionStorage.setItem('surname', state.surname || '');
            sessionStorage.setItem('businessName', state.businessName || '');
            sessionStorage.setItem('phone', state.phone || '');
            sessionStorage.setItem('expiryDate', state.expiryDate || '');
            sessionStorage.setItem('trial', state.trial !== undefined ? String(state.trial) : '');
        }
    },
    extraReducers: (builder) => {
        builder.addCase(signup.fulfilled, (state, action) => {
            console.log(`AUTH - SIGNED UP AS ${action?.meta?.arg?.user?.username || ''}`);
            state.registerError = undefined;
        });
        builder.addCase(signup.rejected, (state, action) => {
            console.error('AUTH - SIGN UP ERROR:', action.error?.message || '');
            state.registerError = action.payload as SerializedError;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            const loggedUser = action.payload;
            state.loginError = undefined;
            state.id = loggedUser.id;
            state.username = loggedUser.username;
            state.accessToken = loggedUser.accessToken;
            state.refreshToken = loggedUser.refreshToken;
            state.name = loggedUser.name;
            state.surname = loggedUser.surname;
            state.businessName = loggedUser.businessName;
            state.phone = loggedUser.phone;
            // toISOString salva la data come stringa YYYY-MM-DDTHH:mm:ss.sssZ in UTC
            state.expiryDate = typeof action.payload?.expiryDate === 'string' ? moment(action.payload.expiryDate).toISOString() : undefined;
            sessionStorage.setItem('id', state.id ? String(state.id) : '');
            sessionStorage.setItem('username', state.username || '');
            sessionStorage.setItem('accessToken', state.accessToken || '');
            sessionStorage.setItem('refreshToken', state.refreshToken || '');
            sessionStorage.setItem('name', state.name || '');
            sessionStorage.setItem('surname', state.surname || '');
            sessionStorage.setItem('businessName', state.businessName || '');
            sessionStorage.setItem('phone', state.phone || '');
            sessionStorage.setItem('expiryDate', state.expiryDate || '');
            sessionStorage.setItem('trial', state.trial !== undefined ? String(state.trial) : '');
            console.log(`AUTH - LOGGED AS ${action?.payload?.username || ''}`);
        });
        builder.addCase(login.rejected, (state, action) => {
            delete state.accessToken;
            delete state.username;
            delete state.refreshToken;
            delete state.name;
            delete state.surname;
            delete state.businessName;
            delete state.phone;
            delete state.expiryDate;
            delete state.trial;
            sessionStorage.removeItem('id');
            sessionStorage.removeItem('username');
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('refreshToken');
            sessionStorage.removeItem('name');
            sessionStorage.removeItem('surname');
            sessionStorage.removeItem('businessName');
            sessionStorage.removeItem('phone');
            sessionStorage.removeItem('expiryDate');
            sessionStorage.removeItem('trial');
            console.error('AUTH - LOGIN ERROR:', action || '');
            action.error = action.payload as SerializedError;
            state.loginError = action.error;
        });
    }
});

export type AuthStateType = ReturnType<typeof auth.reducer>;

export default auth.reducer;

export const { logout, update } = auth.actions;
